import {BasicsClass} from "@/common/BasicsClass";

export default class CouponSetAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/voucher/index',
            name: 'list',
            label: '列表'
        }],
        // ['add', {
        //     url: '/voucher/create',
        //     name: 'add',
        //     label: '添加'
        // }],
        // ['edit', {
        //     url: '/voucher/edit',
        //     name: 'edit',
        //     label: '编辑'
        // }],
        // ['del', {
        //     url: '/voucher/del',
        //     name: 'delete',
        //     label: '删除'
        // }],
    ])


    public voucherindex(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public vouchercreate(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public voucheredit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('edit').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    //优惠券


    public voucherdel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('del').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}








































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import CouponSetAPIClass from "@/common/api/couponSet";
export const pageApiList = getPageAPIs(CouponSetAPIClass);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new CouponSetAPIClass();
  private sellShow: boolean = false;
  private forms: any = {
    value: 0,
    input: "",
  };
  private form: any = {
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.voucherindex(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.total;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private title: string = "";

  private addcoupons(data: any): void {
    this.restForm();
    if (data == 1) {
      this.title = "发布优惠券";
    } else {
      this.title = "编辑优惠券";
      this.forms = data;
    }
    this.sellShow = true;
  }

  private submit(): void {
    if (this.forms.duration > 24) {
      Util.showToast("优惠券时长不能大于24小时");
      return;
    }
    if (this.title == "发布优惠券") {
      this.buyAddressApi.vouchercreate(this.forms, () => {
        this.sellShow = false;
        Util.showToast("发布成功");
        this.search();
      });
    } else {
      this.buyAddressApi.voucheredit(this.forms, () => {
        this.sellShow = false;
        Util.showToast("修改成功");
        this.search();
      });
    }
  }

  private voucherdel(id: string): void {
    this.buyAddressApi.voucherdel({id: id}, () => {
      Util.showToast("删除成功");
      this.search();
    });
  }

  private restForm(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
